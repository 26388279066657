/**
 * Note:
 * To optimize font load, convert the fonts to base64 by
 * ```bash
 * base64 -i UnifySerif_W_Rg.woff2 -o UnifySerif_W_Rg.txt
 * ```
 *
 * Details:
 * - This project does not do well with Nextjs `localFont` as it dynamically generates `font-family` names.
 * - The `font-family` names are crucial to the components in Community-Hub-Components
 */
import { UnifySansBold } from "./UnifySansBold";
import { UnifySansRg } from "./UnifySansRg";
import { UnifySerifBold } from "./UnifySerifBold";
import { UnifySerifRg } from "./UnifySerifRg";

const fonts = [UnifySansBold, UnifySansRg, UnifySerifRg, UnifySerifBold];

// Load fonts using DOM's FontFace
const loadFontString = fonts
  .map(
    (f, idx) => `
const font${idx} = new FontFace("${f.family}", "url(data:application/font-woff2;charset=utf-8;base64,${f.src})");
font${idx}.load();
document.fonts.add(font${idx});`
  )
  .join(" ");

export const hideUntilFontLoaded = `
const main$ = document.querySelector('#__next');
main$.style.display = 'none';
${loadFontString}
document.fonts.ready.then(() => {
main$.style.display = '';
});
`;
